var AlphaVision = require('../alpha-vision-map/alpha-vision-map.js');
var component = require('../../../lib/js/component.js');
var productMapControllerv2 = require('../map/map-product-controller-v2.js');
var utils = require('../../../lib/js/utils.js');
var mapPinHelper = require('../map/map-pin-helper.js');

var ProductMapV2 = function () {

    var viewModel = function() {
        var self = this;
        self.viewObservable = {
        } 
    }
    
    var brandsInLegend = {};
    var _lotMapData = {};

    var viewLookupTable = {
        "community": $(".ProductMapCommunitySidebarV2"),
        "qmi": $(".ProductMapTotalQMISidebarV2")
    };


    this.initialize = function (el, options) {
        var _this = this;
        this.$body = $('body');
        //store api url if it exists
        if (this.$body.data('api-url') && this.$body.data('api-url').length) {
            this.apiUrl = this.$body.data('api-url');
        } else {
            this.apiUrl = window.location.origin;
        }

        var productMapEl = $(document).find('.ProductMapV2');
        var state = productMapEl.attr("data-state");
        var region = productMapEl.attr("data-region");
        var city = productMapEl.attr("data-city");
        var brand = $('body').data('brand');
        _lotMapData.$lotMapContainer = $(".lot-map-container");

        this.productLocation = {
            "brand": brand,
            "flag": "",
            "pageNumber": 0,
            "pageSize": 10,
            "productType": "community",
            "qmi": false,
            "region": region,
            "state": state,
            "cityNames": city,
            "data": "",
            "searchForExact": false
        }

        if (city) {
            this.productLocation.flag = "any";
            this.productLocation.data = city;
        } else if (region) {
            this.productLocation.flag = "region";
            this.productLocation.data = region;
        } else {
            this.productLocation.flag = "state";
            this.productLocation.data = state;
        }

        this.$thisMap = this.$element.find(".Map");

        if (this.$thisMap && this.$thisMap.length > 0) {
            this.$thisMap.attach(productMapControllerv2, Pulte);
        }

        this.$thisMap.on('pinClicked', this.openProductSelection.bind(this));
        this.$thisMap.on('pmap-loadMapLegend', this.loadMapLegend.bind(this));

        $('.ProductMapV2-panel').find('.v-card').each(function(index,element) {
            $(element).attr('data-top',$(element).offset().top);
        });

        $('.ProductMapV2-panel-mobile').find('.v-card').each(function(index,element) {
            $(element).attr('data-left',$(element).offset().left);            
        });

        this.handleView(null,"community");
        $(window).on("resize", utils.debounce(reportWindowSize, 250, false));

        this.$element.on("pf-view-change", this.handleView.bind(this));

        var newModel = new viewModel();
        this.koModel = newModel;
        ko.applyBindings(newModel,el);

        $("[data-click='show-single-qmis']").on("click", this.showSingleQMIs.bind(this));
        $("[data-click='backtototal']").on("click", this.backToTotalQMIs.bind(this));
   }

   this.showSingleQMIs = function(e) {
        e.preventDefault();
        hideAllLookupTables();
        setCommunityData(e);

        var $headerItems = $("[singleqmisidebarheaderitem]");
        var $mobilePanel = $(".ProductMapV2-panel-mobile");
        var $desktopPanel = $("[productmapv2panel]");
        var $desktopContainer = $(".ProductMapV2-single-qmi");
        var $mobileContainer = $(".ProductMapV2-single-qmi-mobile");
        var $qmiCountSpans = $("[qmicount]");

        $headerItems.show();
        $desktopContainer.addClass("ProductMapV2-panel");
        $desktopPanel.removeClass("ProductMapV2-panel").addClass("panel-border");
        $mobilePanel.addClass("single-qmi-active");

        var targetCommunityId = e.target.dataset.communityid;
        var $communityQMIs = $desktopContainer.find("[data-communityid='" + targetCommunityId + "']"); 
        var $communityMobileQMIs = $mobileContainer.find("[data-communityid='" + targetCommunityId + "']"); 
        $communityQMIs.css("display","block");
        $communityMobileQMIs.css("display","block");

        $qmiCountSpans.text($communityQMIs.length); 
        this.showLotMap(targetCommunityId);       
    };

    this.backToTotalQMIs = function(e) {
        this.handleView.call(this, e, "qmi");
        this.hideLotMap();   
    };

    this.hideQMISidebar = function() {
        // hide card contents
        $('[class^="ProductMapV2-single-qmi"]').find(".v-card").hide();

        // hide header contents
        $("[singleqmisidebarheaderitem]").hide();

        $("[productmapv2panel]").addClass("ProductMapV2-panel").removeClass("panel-border");
        $(".ProductMapV2-panel-mobile").removeClass("single-qmi-active");
        $(".ProductMapV2-single-qmi").removeClass("ProductMapV2-panel");
    };

    this.resetPinSelection = function (e) {
        e.preventDefault();
        this.$thisMap.trigger('resetPinSelection');
    };

    this.handleView = function(e,view) {

        // Update map results
        this.productLocation.productType = view;

        if(view === 'qmi'){
            this.productLocation.qmi = true;
        }
        else{
            this.productLocation.qmi = false;
        }

        if (e != null) {
            this.resetPinSelection(e);
        }

        // the location is determined in the map-product-controller-v2
        this.$thisMap.trigger('filterSelected', this.productLocation);

        // this will need to be updated when we add the home options filter to the map sidebar

        // this is to center align the No QMI Available message at the SM and XS breakpoints
        if($('.ProductMapV2').data('qmiCount') == 0 && this.productLocation.productType == 'qmi' && $(window).width() <= 1024){
            $('.ProductMapV2-panel-mobile').addClass('d-none');
        }else{
            $('.ProductMapV2-panel-mobile').removeClass('d-none');
        }

        hideAllLookupTables();
        this.hideQMISidebar();
        this.hideLotMap();   

        Object.values(viewLookupTable).forEach(function(val){
            val.hide()
        });
        viewLookupTable[view].show();
    };

    this.updateMapResults = function (e) {
        this.resetPinSelection(e);

        // the location is determined in the map-product-controller-v2
        this.$thisMap.trigger('filterSelected', this.productLocation);        
    }

    function reportWindowSize() { // this fires many times on page load, added debounce in above init, real fix will be when carousel v2 stops triggering many window resize events on load. 
        $('.ProductMapV2-panel').animate({scrollTop: 0}, 100);

        window.setTimeout(function() { 
            $('.ProductMapV2-panel').find('.v-card').each(function(index,element) {
                $(element).data('top',$(element).offset().top);
            });
            $('.ProductMapV2-panel-mobile').find('.v-card').each(function(index,element) {
                $(element).attr('data-left',$(element).offset().left);            
            });
        }, 1500);
    }
      
      
    this.setMapPinIcon = function ($element, iconSvgProperties) {
        iconSvgProperties.scale = .75;
        var mapPinProperties = mapPinHelper.getGoogleMapPinObject(iconSvgProperties);
        $element.attr({ 'src': mapPinProperties.url, 'width': mapPinProperties.scaledSize.width, 'height': mapPinProperties.scaledSize.height });
    }

    if (utils.is.mobileOrTabletScreen()) {
        $('.legend-container').removeClass('open');
    }

    $('.legend-container > .header-row').on('click', function() {
        if($('.legend-container').hasClass('open')) {
            $('.legend-container').removeClass('open');
        } else {
            $('.legend-container').addClass('open');
        }
    })

    this.loadMapLegend = function (e, brandsInSearch) {

        // Show rows if (and only if) the current search contains that brand
        if (brandsInSearch.pulte) {
            if (!brandsInLegend.pulte) {
                this.setMapPinIcon(this.$element.find('.ProductMap__pulteExact'), { brandName: 'Pulte', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__pulteClose'), { brandName: 'Pulte', pinType: 'close' });
                brandsInLegend.pulte = true;
            }
            this.$element.find('.ProductMap__pulte').show();
        } else {
            this.$element.find('.ProductMap__pulte').hide();
        }

        if (brandsInSearch.pulteActiveAdult) {
            if (!brandsInLegend.pulteActiveAdult) {
                this.setMapPinIcon(this.$element.find('.ProductMap__pulteActiveAdultExact'), { brandName: 'Pulte', pinType: 'exact', isActiveAdult: true });
                this.setMapPinIcon(this.$element.find('.ProductMap__pulteActiveAdultClose'), { brandName: 'Pulte', pinType: 'close', isActiveAdult: true });
                brandsInLegend.pulteActiveAdult = true;
            }
            this.$element.find('.ProductMap__pulteActiveAdult').show();
        } else {
            this.$element.find('.ProductMap__pulteActiveAdult').hide();
        }

        if (brandsInSearch.centex) {
            if (!brandsInLegend.centex) {
                this.setMapPinIcon(this.$element.find('.ProductMap__centexExact'), { brandName: 'Centex', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__centexClose'), { brandName: 'Centex', pinType: 'close' });
                brandsInLegend.centex = true;
            }
            this.$element.find('.ProductMap__centex').show();
        } else {
            this.$element.find('.ProductMap__centex').hide();
        }

        if (brandsInSearch.delwebb) {
            if (!brandsInLegend.delwebb) {
                this.setMapPinIcon(this.$element.find('.ProductMap__delwebbExact'), { brandName: 'DelWebb', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__delwebbClose'), { brandName: 'DelWebb', pinType: 'close' });
                brandsInLegend.delwebb = true;
            }
            this.$element.find('.ProductMap__delwebb').show();
        } else {
            this.$element.find('.ProductMap__delwebb').hide();
        }

        if (brandsInSearch.divosta) {
            if (!brandsInLegend.divosta) {
                this.setMapPinIcon(this.$element.find('.ProductMap__divostaExact'), { brandName: 'DiVosta', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__divostaClose'), { brandName: 'DiVosta', pinType: 'close' });
                brandsInLegend.divosta = true;
            }
            this.$element.find('.ProductMap__divosta').show();
        } else {
            this.$element.find('.ProductMap__divosta').hide();
        }

        if (brandsInSearch.jw) {
            if (!brandsInLegend.jw) {
                this.setMapPinIcon(this.$element.find('.ProductMap__jwExact'), { brandName: 'JW', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__jwClose'), { brandName: 'JW', pinType: 'close' });
                brandsInLegend.jw = true;
            }
            this.$element.find('.ProductMap__jw').show();
        } else {
            this.$element.find('.ProductMap__jw').hide();
        }

        if (brandsInSearch.americanwest) {
            if (!brandsInLegend.americanwest) {
                this.setMapPinIcon(this.$element.find('.ProductMap__americanwestExact'), { brandName: 'AmericanWest', pinType: 'exact' });
                this.setMapPinIcon(this.$element.find('.ProductMap__americanwestClose'), { brandName: 'AmericanWest', pinType: 'close' });
                brandsInLegend.americanwest = true;
            }
            this.$element.find('.ProductMap__americanwest').show();
        } else {
            this.$element.find('.ProductMap__americanwest').hide();
        }
    }

    this.lotClickedAndScroll = function (lotID) {
        var $productPanel = $('.ProductMapV2-panel');
        var isMobile = !$productPanel.is(':visible');
        if (isMobile) {
            $productPanel = $('.ProductMapV2-panel-mobile');
        }

        var $allActiveCards = $productPanel.find('.v-card.active');
        $allActiveCards.removeClass('active');

        var $selectedCard = $productPanel.find("[data-lot-number='" + lotID + "']");
        if($selectedCard.length) {
            $selectedCard.addClass('active');
            
            if(isMobile) {
                var $selectedCardInMobile = $productPanel.find("[data-lot-number='" + lotID + "']");
                var $singleQmiMobile = $('.ProductMapV2-single-qmi-mobile');
                $singleQmiMobile.animate({scrollLeft: $selectedCardInMobile.position().left + $singleQmiMobile.scrollLeft()}, 2000);
            }
            else {
                var scrollOffset = $productPanel.offset().top;
                $productPanel.animate({scrollTop: $selectedCard.position().top + $productPanel.scrollTop() - scrollOffset}, 2000);
            }
        }
    };

    this.openProductSelection = function (e, product) {
        var $productPanel = $('.ProductMapV2-panel');
        if ($('.ProductMapV2-panel-mobile').is(':visible')) {
            $productPanel = $('.ProductMapV2-panel-mobile');
        }
        var $allActiveCards = $productPanel.find('.v-card.active');
        $allActiveCards.removeClass('active');

        var $selectedCard = $productPanel.find("[data-community-id='" + product.communityId + "']");
        if($selectedCard) {
            var currentWindowWidthDifference = $(window).width() - $selectedCard.width();
            var widthOffset = currentWindowWidthDifference / 2;

            $selectedCard.addClass('active');
            var scrollOffset = $('.ProductMapV2-panel').offset().top;
            var positionTop = $selectedCard.data('top');
            var scrollPosition = $(document).scrollTop();
            var stickyHeaderOffset = 0;
            if($('.GlobalHeaderV2').hasClass('is-showing')) {
                stickyHeaderOffset = $('.GlobalHeaderV2').height();
            }

            if((scrollPosition + stickyHeaderOffset) > $('.ProductMapV2-panel').offset().top) {
                scrollOffset = (scrollPosition + stickyHeaderOffset);
            }

            if($('.ProductMapV2-panel-mobile').is(':visible')) {
                $productPanel.animate({scrollLeft: $selectedCard.data('left') - (widthOffset - 5)}, 2000);
            }
            else {
                $productPanel.animate({scrollTop: positionTop - scrollOffset}, 2000);
            }
        }
    };

    function hideAllLookupTables() {
        Object.values(viewLookupTable).forEach(function(val){
            val.hide()
        });
    }

    function setCommunityData(e) {
        var brandLogo = e.target.dataset.communityBannerLogo;
        var communityName = e.target.dataset.communityName;

        $("[communitybannerimage]").attr("src", brandLogo);
        $("[communityname]").html(communityName);
    }

    this.showLotMap = function (targetCommunityId) {
        var enableLotMap =  _lotMapData.$lotMapContainer[0].getAttribute("data-enable-lot-map") === "True";

        if(!enableLotMap)return;

        this.setupAlphaVisionApi.call(this, targetCommunityId);
    }

    this.hideLotMap = function () {
        $(".Map-container").show();
        _lotMapData.$lotMapContainer.hide().html("");
        _lotMapData.isLotMapVisible = false;
        _lotMapData.hasQMI = false;
        _lotMapData.isReady = false;
    }

    this.setupAlphaVisionApi = function (targetCommunityId) {
        var lotMapContainer = _lotMapData.$lotMapContainer[0];
        this.mapApi = new AVSiteplan(lotMapContainer.getAttribute("data-builder-guid"),
            targetCommunityId, 
            lotMapContainer,
            this.genericCallback.bind(this),
            this.eventCallback.bind(this)
        );
    }

    this.eventCallback = function (response) {
        this.selectQMILot(response.data.lotGroupName, response.data.lotLabel);
        this.lotClickedAndScroll(response.data.lot);
    }

    this.genericCallback = function (response) {
        if (response.datatype.toLowerCase() == "mastermap") {
            this.setSitePlanAndLot.call(this);

            if (!_lotMapData.hasQMI) return;

            this.mapApi.selectMap(_lotMapData.sitePlan);
            this.showLotMapOnSuccess();
        }
        else if (response.datatype.toLowerCase() == "siteplan") {
            this.setSitePlanAndLot.call(this);

            if (!_lotMapData.hasQMI) return;
            
            var lotArray= this.mapApi.filterLots("Quick Move In").lots;
            for (var i = 0; i < lotArray.length; i++) {
                $("#g" + lotArray[i].lotGroupName + "_l" + lotArray[i].lotNumber).addClass("qmis-lots");
            }

            this.selectQMILot(_lotMapData.groupNumber, _lotMapData.lotLabel);
            this.lotClickedAndScroll(_lotMapData.lotNumber);
            this.showLotMapOnSuccess();
        }
    }

    this.setSitePlanAndLot = function() {
        if (_lotMapData.isReady) return;

        var $qmiCard = $("[qmicontainer]").find(".v-card:visible:first");
        var firstCardLotNumber = $qmiCard.data().lotNumber;

        _lotMapData.hasQMI = this.mapApi.alphamapApiResult.Siteplans.some(function (sitePlan) {
            return sitePlan.LotDetails.some(function (singleLot) {
                return singleLot.status === "Quick Move In";
            });
        });

        if (!_lotMapData.hasQMI) return;

        this.mapApi.alphamapApiResult.Siteplans.some(function (sitePlan) {
            // set first site plan in case no lot matches
            if (!_lotMapData.sitePlan) _lotMapData.sitePlan = sitePlan.SiteplanName;

            return sitePlan.LotDetails.some(function (singleLot) {
                
                if(singleLot.Lot == firstCardLotNumber){
                    _lotMapData.groupNumber = singleLot.LotGroupName;
                    _lotMapData.lotLabel = singleLot.LotLabel;
                    _lotMapData.lotNumber = firstCardLotNumber;
                    _lotMapData.sitePlan = sitePlan.SiteplanName;
                    return true;
                }
            });
        });

        _lotMapData.isReady = true;
    };

    this.showLotMapOnSuccess = function() {
        if (!_lotMapData.isLotMapVisible) {
            $(".Map-container").hide();
            _lotMapData.$lotMapContainer.css("display", "grid");
            _lotMapData.isLotMapVisible = true;
        }
    };

    this.selectQMILot = function (groupNumber,lotNumber) {
        var LotClickedId = "g" + groupNumber + "_l" + lotNumber;
        $(".lotPolygon").removeAttr("style");
        var lotMapContainer = _lotMapData.$lotMapContainer[0];
        var brandColor = lotMapContainer.getAttribute("data-highlight-color");
        $("#" + LotClickedId).css("fill", brandColor);
    }
};

module.exports = component(ProductMapV2);
